<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->

      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              Forgot Password? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Enter your associate phone number with your username and we'll send you instructions to reset your password
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotPasswordForm">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForgotPasswordForm"
              >
                <!-- username -->
                <b-form-group
                  label="User Name"
                  label-for="forgot-password-userName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="User Name"
                    vid="userName"
                    rules="required"
                  >
                    <b-form-input
                      id="forgot-password-userName"
                      v-model="userName"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-userName"
                      placeholder="Enter User Name"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Phone Number"
                  label-for="forgot-password-phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="forgot-password-phone"
                      v-model="phone"
                      :state="errors.length > 0 ? false: null"
                      name="forgot-password-phone"
                      placeholder="012****890"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  block
                >
                  Send OTP
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link
                :to="{name:'auth-login'}"
                :disabled="isLoading"
              >
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </p>
          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/@service/api/Auth.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

import Header from '@/@core/components/landding-page/Header.vue'
import MediaIcon from '@/@core/components/landding-page/MediaIcon.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import CoverImage from '@/@core/components/landding-page/CoverImage.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    // BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    Header,
    MediaIcon,
    Footer,
    CoverImage,
  },
  data() {
    return {
      userName: '',
      phone: '',
      isLoading: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    if (this.getPageData) {
      this.pageData = this.getPageData
    } else {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    validationForgotPasswordForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          AuthService.forgotPasswordReset({
            user_username: this.userName,
            phone: this.phone,
          }).then(
            res => {
              const response = res.data
              this.isLoading = false

              if (!response.status && response?.validation_error) {
                const validation = response?.validation_error

                if (validation.user_username) {
                  this.$refs.forgotPasswordForm.setErrors({
                    userName: [validation.user_username ? validation.user_username[0] : null],
                  })
                }

                if (validation.phone) {
                  this.$refs.forgotPasswordForm.setErrors({
                    phone: [validation.phone ? validation.phone[0] : null],
                  })
                }

                FemsToastrService.error(response?.message)
                return
              }

              if (!response.status) {
                this.$refs.forgotPasswordForm.setErrors({ userName: [response.message] })
                FemsToastrService.error(response.message || 'Something went wrong')
                return
              }

              if (response.isOtpVerification) {
                const data = response?.data
                // otp verification data set as localstorage
                LocalStorageService.set('user_username', JSON.stringify(data.user_username))
                LocalStorageService.set('verificationData', JSON.stringify(data))

                FemsToastrService.success(response.message || 'Already send OTP please enter the OTP.')

                this.$router.push({ name: 'otp-verify' })
              }
            },
          ).catch(
            error => {
              this.isLoading = false

              let errorMessage = 'Something went wrong'
              if (error?.errors?.user_username) {
                errorMessage = error?.errors?.user_username[0]
              }
              this.$refs.forgotPasswordForm.setErrors({ userName: [error?.message] })
              FemsToastrService.error(errorMessage || error.message)
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
